import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    CROWDING,
    STRESS_DETECTION_API,
    STRESS_ENGINE_START_API,
    STRESS_ENGINE_STATUS_API,
    STRESS_ENGINE_STOP_API,
    STRESS_ENGINE_STRESS_LEVEL_API
} from 'common/constants';
import { formatSeconds } from 'common/utils';
import { API, STRESS_ENGINE_API } from 'services';

export const getServiceBoats = createAsyncThunk(
    'stressDetections/getServiceBoats',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${STRESS_DETECTION_API}/service-boats`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStressEngineStatus = createAsyncThunk(
    'stressDetections/getStressEngineStatus',
    async ({ baseUrl }, { rejectWithValue }) => {
        try {
            const { data } = await STRESS_ENGINE_API.get(baseUrl + STRESS_ENGINE_STATUS_API);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const startStressEngine = createAsyncThunk(
    'stressDetections/startStressEngine',
    async (
        {
            baseUrl = '',
            penNumber = 1,
            siteName = undefined,
            farmerOrganizationId = '',
            eventType = CROWDING
        },
        { rejectWithValue }
    ) => {
        try {
            const { data } = await STRESS_ENGINE_API.get(
                `${baseUrl}${STRESS_ENGINE_START_API}/${penNumber}/${siteName}/${farmerOrganizationId}/${eventType}`
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const stopStressEngine = createAsyncThunk(
    'stressDetections/stopStressEngine',
    async (
        {
            baseUrl = '',
            penNumber = 1,
            siteName = undefined,
            farmerOrganizationId = '',
            eventType = CROWDING
        },
        { rejectWithValue }
    ) => {
        try {
            const { data } = await STRESS_ENGINE_API.get(
                `${baseUrl}${STRESS_ENGINE_STOP_API}/${penNumber}/${siteName}/${farmerOrganizationId}/${eventType}`
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStreamingStressLevels = createAsyncThunk(
    'stressDetections/getStreamingStressLevels',
    async ({ baseUrl }, { rejectWithValue }) => {
        try {
            const { data } = await STRESS_ENGINE_API.get(baseUrl + STRESS_ENGINE_STRESS_LEVEL_API);

            if (!data) return [];

            const stressLevels = data
                .trim()
                .split('\n') // Split into lines
                .map((line) => line.split(',').map(Number))
                .map(([time, level]) => ({
                    name: formatSeconds(time),
                    value: level
                }));

            return stressLevels;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
