import { STRESS_ENGINE_STREAMING_STATUS_API } from 'common/constants';
import { formatSeconds } from 'common/utils';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { stressDetectionStreamingSelector } from 'redux/selector';
import { AUTH_SERVICE } from 'services';
import propTypes from 'prop-types';

const StreamingTimeStatus = ({ onStressEngineStop = () => {} }) => {
    const streaming = useSelector(stressDetectionStreamingSelector);
    const eventSourceRef = useRef();
    const [stressDetectionTime, setStressDetectionTime] = useState(0);

    const handleStreamingMessage = useCallback((e) => {
        const streamingData = JSON.parse(e.data);

        if (!streamingData.isDetecting) {
            onStressEngineStop();
            eventSourceRef.current.close();
            return;
        }

        setStressDetectionTime(streamingData?.detectingTime || 0);
    }, []);

    useEffect(() => {
        const startStreaming = async () => {
            if (eventSourceRef) {
                eventSourceRef.current = await getStreamingStatus();
                eventSourceRef.current.onmessage = handleStreamingMessage;
            }
        };
        startStreaming();

        return () => {
            if (eventSourceRef?.current) {
                eventSourceRef.current.close();
            }
        };
    }, [handleStreamingMessage]);

    const getStreamingStatus = async () => {
        const streamingStatusAPI = streaming.engineBaseUrl + STRESS_ENGINE_STREAMING_STATUS_API;

        const token = await AUTH_SERVICE.getToken();
        const headers = {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'text/event-stream'
        };

        return new EventSourcePolyfill(streamingStatusAPI, { headers });
    };

    return <h2>{formatSeconds(stressDetectionTime)}</h2>;
};

StreamingTimeStatus.propTypes = {
    onStressEngineStop: propTypes.func
};

export default StreamingTimeStatus;
