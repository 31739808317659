import { AlignLeftOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Layout as LibLayout, Spin } from 'antd';
import fishwellLogo from 'assets/Fishwell_Logo.png';
import fishwellTechnologyLogo from 'assets/Fishwell_Technology_Logo.png';
import { LAYOUT_TYPE, SETUP_ACCOUNT_API } from 'common/constants';
import { alertErrorMessage } from 'common/utils';
import { FDGlobalBoundaryError } from 'components';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { getCurrentProfile } from 'redux/thunks';
import { API } from 'services';
import { default as CountingHeader } from './CountingLayout/Header/Header';
import { default as CountingSidebar } from './CountingLayout/Sidebar/Sidebar';
import Footer from './Footer/Footer';
import { Header as DefaultHeader } from './Header';
import './Layout.scss';
import { Sidebar } from './Sidebar';

const { Content } = LibLayout;

const Layout = ({ layout = LAYOUT_TYPE.DEFAULT }) => {
    const dispatch = useAppDispatch();
    const { accounts } = useMsal();

    const [collapsed] = useState(false);
    const [isSetupAccountLoading, setIsSetupAccountLoading] = useState(true);

    const organizationId = useSelector((s) => get(s, 'profile.data.company.organizationId'));
    const userEmail = useSelector((s) => get(s, 'profile.data.email'));

    const containerStyle = useMemo(() => {
        return { marginLeft: collapsed ? 80 : 250 };
    }, [collapsed]);

    useEffect(() => {
        const setupAccount = async () => {
            try {
                await API.post(SETUP_ACCOUNT_API);
                dispatch(getCurrentProfile()).then(() => setIsSetupAccountLoading(false));
            } catch (error) {
                alertErrorMessage(error);
            }
        };

        setupAccount();
    }, []);

    const ldProviderConfig = {
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
        context: {
            kind: 'multi',
            Tenant: {
                key: get(accounts[0], 'tenantId', ''),
                name: 'Tenant'
            },
            ...(organizationId && {
                OrganizationID: {
                    key: organizationId,
                    name: 'OrganizationID'
                }
            }),
            ...(userEmail && {
                UserEmail: {
                    key: userEmail,
                    name: 'UserEmail'
                }
            })
        }
    };

    const handleToggleMenu = (isToggleIcon = false) => {
        const sidebar = document.getElementsByClassName('sidebar')[0];
        const container = document.getElementsByClassName('main')[0];

        if (sidebar.classList.contains('sidebar-responsive')) {
            sidebar.classList.remove('sidebar-responsive');
            container.classList.remove('main-blur');
        } else if (isToggleIcon) {
            sidebar.classList.add('sidebar-responsive');
            container.classList.add('main-blur');
        }
    };

    if (isSetupAccountLoading) {
        return (
            <div className="min-h-[100vh] bg-white flex flex-col justify-center items-center">
                <img src={fishwellTechnologyLogo} alt="Fishwell Technology Logo" className="w-72" />
                <Spin></Spin>
            </div>
        );
    }

    const RenderLayout = () => {
        return layout === LAYOUT_TYPE.DEFAULT ? (
            <LibLayout hasSider className="layout min-h-screen">
                <Sidebar />

                <AlignLeftOutlined className="toggle-icon" onClick={() => handleToggleMenu(true)} />

                <LibLayout
                    className="layout-container"
                    style={containerStyle}
                    onClick={() => handleToggleMenu()}
                >
                    <DefaultHeader />
                    <Content className="main pt-3">
                        <ErrorBoundary FallbackComponent={FDGlobalBoundaryError}>
                            <Outlet />
                        </ErrorBoundary>
                    </Content>

                    <Footer />
                </LibLayout>
            </LibLayout>
        ) : (
            <LibLayout hasSider className="layout counting-layout min-h-screen">
                <CountingSidebar />

                <LibLayout className="layout-container" style={containerStyle}>
                    <AlignLeftOutlined className="toggle-icon" onClick={handleToggleMenu} />

                    <CountingHeader />

                    <Content className="main">
                        <div className="flex justify-center mb-8">
                            <img
                                src={fishwellLogo}
                                alt="fishwell-logo"
                                className="pl-10 xs:w-3/4 md:w-2/3 xl:w-1/3"
                            />
                        </div>
                        <ErrorBoundary FallbackComponent={FDGlobalBoundaryError}>
                            <Outlet />
                        </ErrorBoundary>
                    </Content>

                    <Footer />
                </LibLayout>
            </LibLayout>
        );
    };

    const LDProvider = withLDProvider(ldProviderConfig)(RenderLayout);

    return <LDProvider />;
};

Layout.propTypes = {
    layout: PropTypes.string
};

export default Layout;
