import {
    CaretRightOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    EyeOutlined,
    InfoCircleFilled,
    StopOutlined
} from '@ant-design/icons';
import { Spin } from 'antd';
import { Button } from 'antd';
import {
    BLUE,
    BUTTON_BLUE_CLASS,
    BUTTON_LIGHT_GREEN_CLASS,
    BUTTON_RED_CLASS,
    GRAY,
    LIGHT_GRAY,
    LIGHT_GREEN,
    ORANGE,
    STRESS_DETECTION_PAGE
} from 'common/constants';
import { alertErrorMessage } from 'common/utils';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userOrganizationIdSelector } from 'redux/selector';
import { setStressDetectionStreaming } from 'redux/slices';
import { useAppDispatch } from 'redux/store';
import { getStressEngineStatus, stopStressEngine } from 'redux/thunks';
import { NOTIFICATION_SERVICE } from 'services';
import Swal from 'sweetalert2';
import propTypes from 'prop-types';

const ServiceBoatStatus = ({ serviceBoat = {}, onPenListOpen = () => {} }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userOrganizationId = useSelector(userOrganizationIdSelector);

    useEffect(() => {
        if (serviceBoat.hasEngineBaseUrl) {
            dispatch(
                getStressEngineStatus({
                    baseUrl: serviceBoat.engineBaseUrl,
                    serviceBoatId: serviceBoat.id
                })
            );
        }
    }, [serviceBoat.engineBaseUrl]);

    const handlerStartButtonClick = () => {
        onPenListOpen(serviceBoat);
    };

    const handleStopButtonClick = () => {
        const handleStressEngineStop = async () => {
            try {
                await dispatch(
                    stopStressEngine({
                        baseUrl: serviceBoat.engineBaseUrl,
                        penNumber: serviceBoat.penNumber,
                        farmerOrganizationId: userOrganizationId,
                        serviceBoatId: serviceBoat.id
                    })
                ).unwrap();

                NOTIFICATION_SERVICE.pushNotification({
                    title: t('stressDetection.notification.stopDetection'),
                    duration: 10
                });

                dispatch(
                    getStressEngineStatus({
                        baseUrl: serviceBoat.engineBaseUrl,
                        serviceBoatId: serviceBoat.id
                    })
                );
            } catch (error) {
                alertErrorMessage(error);
            }
        };

        Swal.fire({
            title: t('stressDetection.stopConfirmation'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('button.yes'),
            cancelButtonText: t('button.cancel')
        }).then(async (result) => {
            if (result.isConfirmed) {
                handleStressEngineStop();
            }
        });
    };

    const handleViewButtonClick = () => {
        dispatch(
            setStressDetectionStreaming({
                isDetecting: true,
                engineBaseUrl: serviceBoat.engineBaseUrl,
                serviceBoatId: serviceBoat.id,
                serviceBoatName: serviceBoat.name,
                penNumber: serviceBoat.penNumber
            })
        );
        navigate(STRESS_DETECTION_PAGE);
    };

    const getServiceBoatStyle = () => {
        if (serviceBoat.isDetecting) {
            return {
                borderColor: 'border-blue-500',
                icon: <Spin />,
                status: `${t('stressDetection.isDetecting')} ${serviceBoat?.penNumber ?? ''}`,
                button: (
                    <>
                        <Button
                            className={BUTTON_BLUE_CLASS}
                            icon={<EyeOutlined />}
                            onClick={() => handleViewButtonClick()}
                        >
                            {t('button.view')}
                        </Button>
                        <Button
                            className={BUTTON_RED_CLASS}
                            icon={<StopOutlined />}
                            onClick={() => handleStopButtonClick()}
                        >
                            {t('button.stop')}
                        </Button>
                    </>
                )
            };
        }

        if (serviceBoat.hasEngineBaseUrl) {
            return {
                borderColor: 'border-green-600',
                icon: <CheckCircleFilled style={{ fontSize: '18px', color: LIGHT_GREEN }} />,
                status: t('stressDetection.readyToStart'),
                button: (
                    <Button
                        className={BUTTON_LIGHT_GREEN_CLASS}
                        icon={<CaretRightOutlined />}
                        onClick={() => handlerStartButtonClick()}
                    >
                        {t('button.start')}
                    </Button>
                )
            };
        }

        if (serviceBoat.hasEngineConfig) {
            return {
                borderColor: 'border-orange-500',
                icon: <InfoCircleFilled style={{ fontSize: '18px', color: ORANGE }} />,
                status: t('stressDetection.noEngineBaseUrl')
            };
        }

        return {
            borderColor: 'border-gray-500',
            icon: <CloseCircleFilled style={{ fontSize: '18px', color: GRAY }} />,
            status: t('stressDetection.noEngineConfig')
        };
    };

    return (
        <Spin spinning={serviceBoat.loading || false}>
            <div
                className={`border border-solid rounded p-2 bg-white w-48 h-36 ${
                    getServiceBoatStyle().borderColor
                }`}
            >
                <div className="flex items-center">
                    {getServiceBoatStyle().icon}
                    <span className="font-bold ml-2 truncate">{serviceBoat.name}</span>
                </div>
                <p className="font-medium mt-2">{getServiceBoatStyle().status}</p>

                <div className="flex justify-around">{getServiceBoatStyle().button}</div>
            </div>
        </Spin>
    );
};

ServiceBoatStatus.propTypes = {
    serviceBoat: propTypes.object,
    onPenListOpen: propTypes.func
};

export default memo(ServiceBoatStatus);
