import { QuestionOutlined } from '@ant-design/icons';
import { Button, Row, Tooltip } from 'antd';
import PropTypes from 'prop-types';

const FDTooltip = ({ explainInfo = [] }) => {
    const descriptionInfo = (
        <Row gutter={24}>
            {explainInfo?.map((explain, index) => (
                <table className="ml-5" key={index}>
                    <tbody>
                        <tr className="bg-slate">
                            <td className="text-right">
                                <span className="text-white text-base mr-2">{explain.title}:</span>
                            </td>
                            <td className="text-left">{explain.description}</td>
                        </tr>
                    </tbody>
                </table>
            ))}
        </Row>
    );

    return (
        <Tooltip placement="topRight" title={descriptionInfo}>
            <Button
                data-testid="btn-tool-tip-daily-report"
                type="primary"
                shape="circle"
                size="small"
                icon={<QuestionOutlined />}
            />
        </Tooltip>
    );
};

FDTooltip.propTypes = {
    explainInfo: PropTypes.array
};

export default FDTooltip;
