import { createSelector } from '@reduxjs/toolkit';

const stressDetectionSelector = (state) => state.stressDetection;

export const stressDetectionServiceBoatsSelector = createSelector(
    stressDetectionSelector,
    (stressDetection) => stressDetection.serviceBoats
);

export const stressDetectionStreamingSelector = createSelector(
    stressDetectionSelector,
    (stressDetection) => stressDetection.streaming
);
