import { OrganizationGraph } from '@ant-design/graphs';
import { appInsights } from 'AppInsights';
import { Card } from 'antd';
import { FISH_GROUP_STATUS, GREEN, LIGHT_BLUE, WHITE } from 'common/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DeadFishMortality from './DeadFishMortality';
import DetailCard from './DetailCard';
import HealthConditionCard from './HealthConditionCard';
import ReportTable from './ReportTable';

const HierarchyChart = () => {
    const { t } = useTranslation();

    const { data: fishGroupTree, loading } = useSelector(
        (s) => s.fishGroupTreeByFishwellFishGroupId
    );
    const fishwellFishIdSelectionLoading = useSelector((s) => s.fishwellFishIdSelection.loading);

    const initialFilters = useSelector((s) => s.filter.data);

    const [selectedFishGroupId, setSelectedFishGroupId] = useState('');
    const fishGroupDetail = fishGroupTree.find((item) => item.id === selectedFishGroupId);

    useEffect(() => {
        if (initialFilters.selectedFishGroupId)
            setSelectedFishGroupId(initialFilters.selectedFishGroupId);
    }, [initialFilters.selectedFishGroupId]);

    const mapDataToOrganizationGraph = () => {
        const rootItem = fishGroupTree.reduce(
            (min, item) => (item.actionDate < min.actionDate ? item : min),
            fishGroupTree[0]
        );

        const dictionary = fishGroupTree.reduce(
            (result, item) => (
                (result[item.id] = {
                    id: item.id,
                    value: {
                        name: `${item.fishGroupId}-${item.sibling}`,
                        title: `${item.siteName}-${item.penNumber}`
                    },
                    parentId: item.parentId,
                    children: []
                }),
                result
            ),
            {}
        );

        fishGroupTree.forEach((item) => {
            if (item.id === rootItem.id) return;

            if (item.status === FISH_GROUP_STATUS.RELEASED && dictionary[item.parentId]?.status)
                dictionary[item.parentId].status = FISH_GROUP_STATUS.RELEASED;
            else if (dictionary[item.parentId]?.children)
                dictionary[item.parentId].children.push(dictionary[item.id]);
        });

        return dictionary[rootItem.id];
    };

    return !fishGroupDetail || loading || fishwellFishIdSelectionLoading ? (
        <Card loading={true}></Card>
    ) : (
        <>
            <h2 className="mb-2">{t('fishGroup.historyOfFFI.title')}</h2>
            <OrganizationGraph
                data={mapDataToOrganizationGraph()}
                behaviors={[]}
                onReady={(graph) => {
                    graph.on('node:click', (evt) => {
                        const { id } = evt.item.get('model');

                        window.scrollTo({ top: 650, behavior: 'smooth' });

                        setSelectedFishGroupId(id);

                        appInsights.trackEvent({
                            name: 'FFI hierarchy chart click',
                            properties: { selectedFishGroupId: id }
                        });
                    });
                }}
                animate={false}
                nodeCfg={{
                    autoWidth: true,
                    style: (node) => ({
                        cursor: 'pointer',
                        fill: node.id === selectedFishGroupId ? LIGHT_BLUE : WHITE,
                        stroke: node.status === FISH_GROUP_STATUS.RELEASED ? GREEN : LIGHT_BLUE
                    }),
                    label: {
                        style: (node, group, type) => {
                            const baseStyle = { cursor: 'pointer' };

                            const styles = {
                                title: { fill: GREEN },
                                name: { fill: GREEN }
                            };

                            return node.status === FISH_GROUP_STATUS.RELEASED
                                ? { ...styles[type], ...baseStyle }
                                : baseStyle;
                        }
                    }
                }}
            />

            <DetailCard fishGroupDetail={fishGroupDetail} />

            <HealthConditionCard fishGroupDetail={fishGroupDetail} />

            <DeadFishMortality fishGroupDetail={fishGroupDetail} />

            <ReportTable fishGroupDetail={fishGroupDetail} />
        </>
    );
};

export default HierarchyChart;
