import { createSlice } from '@reduxjs/toolkit';
import {
    getProcessFlowDetection,
    getProcessList,
    getProcessStressEvent,
    getProcessWelfareScore
} from 'redux/thunks';

const initialState = {
    list: { data: [], loading: false },
    stressEvent: { data: {}, loading: false },
    flowDetection: { data: {}, loading: false },
    welfareScore: { data: {}, loading: false }
};

export const processDetailSlice = createSlice({
    name: 'processDetailSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProcessList.pending, (state) => {
            state.list.loading = true;
        });
        builder.addCase(getProcessList.fulfilled, (state, action) => {
            state.list.loading = false;
            state.list.data = action.payload;
        });
        builder.addCase(getProcessList.rejected, (state) => {
            state.list.loading = false;
            state.list.data = initialState.list.data;
        });

        builder.addCase(getProcessStressEvent.pending, (state) => {
            state.stressEvent.loading = true;
        });
        builder.addCase(getProcessStressEvent.fulfilled, (state, action) => {
            state.stressEvent.loading = false;
            state.stressEvent.data = action.payload || initialState.stressEvent.data;
        });
        builder.addCase(getProcessStressEvent.rejected, (state) => {
            state.stressEvent.loading = false;
            state.stressEvent.data = initialState.stressEvent.data;
        });

        builder.addCase(getProcessFlowDetection.pending, (state) => {
            state.flowDetection.loading = true;
        });
        builder.addCase(getProcessFlowDetection.fulfilled, (state, action) => {
            state.flowDetection.loading = false;
            state.flowDetection.data = action.payload || initialState.flowDetection.data;
        });
        builder.addCase(getProcessFlowDetection.rejected, (state) => {
            state.flowDetection.loading = false;
            state.flowDetection.data = initialState.flowDetection.data;
        });

        builder.addCase(getProcessWelfareScore.pending, (state) => {
            state.welfareScore.loading = true;
        });
        builder.addCase(getProcessWelfareScore.fulfilled, (state, action) => {
            state.welfareScore.loading = false;
            state.welfareScore.data = action.payload || initialState.welfareScore.data;
        });
        builder.addCase(getProcessWelfareScore.rejected, (state) => {
            state.welfareScore.loading = false;
            state.welfareScore.data = initialState.welfareScore.data;
        });
    }
});
