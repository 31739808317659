import {
    AreaChartOutlined,
    CalculatorOutlined,
    LogoutOutlined,
    UserOutlined
} from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Avatar, Menu, Popover } from 'antd';
import defaultAvatar from 'assets/Default_Avatar.png';
import { ENGINE_COUNTING_URL, LIVE_COUNTING_PAGE } from 'common/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const DropdownProfile = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const { countFishSwitch, showLiveCounting } = useFlags();

    const { data: profile } = useSelector((s) => s.profile);

    const items = [
        {
            label: (
                <Link to="/profile">
                    <UserOutlined /> {t('layout.header.viewProfile')}
                </Link>
            ),
            key: 'profile',
            className: 'hover:bg-gray-300'
        },
        { type: 'divider' },
        ...(countFishSwitch
            ? [
                  {
                      label: (
                          <Link to={ENGINE_COUNTING_URL}>
                              <CalculatorOutlined /> {t('layout.header.countFish')}
                          </Link>
                      ),
                      key: 'count-fish',
                      className: 'hover:bg-gray-300'
                  },
                  { type: 'divider' }
              ]
            : []),
        ...(showLiveCounting
            ? [
                  {
                      label: (
                          <Link to={LIVE_COUNTING_PAGE}>
                              <AreaChartOutlined /> {t('layout.header.stressDetection')}
                          </Link>
                      ),
                      key: 'stress-detection',
                      className: 'hover:bg-gray-300'
                  },
                  { type: 'divider' }
              ]
            : []),
        {
            label: (
                <>
                    <LogoutOutlined /> {t('layout.header.logout')}
                </>
            ),
            key: 'logout',
            onClick: () => instance.logoutRedirect(),
            className: 'hover:bg-gray-300'
        }
    ];

    return (
        <Popover
            className="profile-dropdown flex items-center"
            getPopupContainer={(trigger) => trigger.parentElement}
            content={<Menu mode="inline" className="auth-menu" selectable={false} items={items} />}
            placement="bottomRight"
        >
            <span className="user-name cursor-pointer hidden xxl:!inline mr-1 whitespace-nowrap">
                {t('layout.header.greeting')}&nbsp;{profile.name || '...'}
            </span>
            <Avatar className="cursor-pointer w-8" src={profile.avatar || defaultAvatar} />
        </Popover>
    );
};

export default DropdownProfile;
