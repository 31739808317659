import { Spin } from 'antd';
import { STRESS_DETECTION_PAGE } from 'common/constants';
import { alertErrorMessage } from 'common/utils';
import { withPageViewTracking } from 'hoc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NotFound } from 'pages';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { stressDetectionServiceBoatsSelector, userOrganizationIdSelector } from 'redux/selector';
import { resetStressDetectionServiceBoats, setStressDetectionStreaming } from 'redux/slices';
import { useAppDispatch } from 'redux/store';
import { getServiceBoats, startStressEngine } from 'redux/thunks';
import { NOTIFICATION_SERVICE } from 'services';
import PenList from './PenList';
import ServiceBoatStatus from './ServiceBoatStatus';

const StressDetection = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { showLiveCounting } = useFlags();
    const [penListState, setPenListState] = useState({ isOpen: false, serviceBoat: null });

    const { data: serviceBoats, loading } = useSelector(stressDetectionServiceBoatsSelector);
    const userOrganizationId = useSelector(userOrganizationIdSelector);

    useEffect(() => {
        dispatch(getServiceBoats());

        return () => {
            dispatch(resetStressDetectionServiceBoats());
        };
    }, []);

    const handlePenListClose = useCallback(() => {
        setPenListState({ isOpen: false, serviceBoat: null });
    }, []);

    const handlePenListOpen = useCallback((serviceBoat) => {
        setPenListState({ isOpen: true, serviceBoat });
    }, []);

    const handleStressEngineStart = useCallback(
        async (penNumber) => {
            try {
                const response = await dispatch(
                    startStressEngine({
                        baseUrl: penListState.serviceBoat?.engineBaseUrl,
                        penNumber,
                        farmerOrganizationId: userOrganizationId
                    })
                ).unwrap();

                if (response.hasBeenStarting) {
                    NOTIFICATION_SERVICE.pushNotification({
                        title: t('stressDetection.notification.hasStartedDetection'),
                        duration: 15
                    });
                } else {
                    NOTIFICATION_SERVICE.pushNotification({
                        title: t('stressDetection.notification.startDetection'),
                        duration: 10
                    });
                }

                dispatch(
                    setStressDetectionStreaming({
                        isDetecting: true,
                        engineBaseUrl: penListState.serviceBoat?.engineBaseUrl,
                        serviceBoatId: penListState.serviceBoat?.id,
                        serviceBoatName: penListState.serviceBoat?.name,
                        penNumber
                    })
                );

                handlePenListClose();
                navigate(STRESS_DETECTION_PAGE);
            } catch (err) {
                alertErrorMessage(err);
            }
        },
        [penListState.serviceBoat, userOrganizationId, handlePenListClose]
    );

    if (!showLiveCounting) {
        return <NotFound />;
    }

    return (
        <div className="counting-page">
            <div className="mt-10">
                <h2>{t('stressDetection.serviceBoatStatus')}</h2>

                {loading ? (
                    <Spin />
                ) : serviceBoats.length ? (
                    <div className="flex flex-wrap gap-5">
                        {serviceBoats.map((boat) => (
                            <ServiceBoatStatus
                                serviceBoat={boat}
                                key={boat.id}
                                onPenListOpen={handlePenListOpen}
                            />
                        ))}
                    </div>
                ) : (
                    <p className="m-0 text-base text-red-600">
                        {t('stressDetection.noServiceBoatAvailable')}
                    </p>
                )}
            </div>

            <PenList
                isOpen={penListState.isOpen}
                onClose={handlePenListClose}
                onEngineStart={(penNumber) => handleStressEngineStart(penNumber)}
            />
        </div>
    );
};

export default withPageViewTracking(StressDetection, 'Stress detection');
