import { getTimeAgo } from 'common/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './NotificationItem.scss';

const NotificationItem = ({ notification, onNotificationItemClick = () => {} }) => {
    const { t } = useTranslation();

    return (
        <div
            className="notification-item xs:w-60 sm:w-96 text-slate-900 flex items-center justify-between"
            data-testid="notification-item"
            onClick={onNotificationItemClick}
            role="button"
        >
            <div className="content">
                <p className="m-0 font-semibold text-base">{t(notification.title)}</p>
                <p className="m-0">
                    {t('notificationHub.locations')}: {t('notificationHub.site')}{' '}
                    {notification.siteName}, {t('notificationHub.pen')} {notification.penNumber}
                </p>
                {notification.notes && (
                    <p className="m-0">
                        {t('notificationHub.notes')}: {notification.notes}
                    </p>
                )}
                <p className="m-0 font-bold text-sm text-sky-900">
                    {getTimeAgo(notification.sentTime)}
                </p>
            </div>

            {!notification.readTime && (
                <div className="dot h-2 w-2 rounded-full bg-sky-900" data-testid="unread-dot"></div>
            )}
        </div>
    );
};

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        title: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
        penNumber: PropTypes.string.isRequired,
        notes: PropTypes.string,
        sentTime: PropTypes.string.isRequired,
        readTime: PropTypes.string
    }).isRequired,
    onNotificationItemClick: PropTypes.func
};

export default NotificationItem;
